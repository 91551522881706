import React, { useState } from "react";
import Buttoncomp from "../UI/Buttoncomp";

const Navbarre = ({ setSelectedDeck, selectedDeck, titlebook }) => {
  const [decks, setDecks] = useState([1, 2, 3, 4, 5]); // Initialisation avec 5 livres

  // Fonction pour gérer la sélection du deck (livre)
  const handleDeckClick = (deckNumber) => {
    setSelectedDeck(deckNumber); // Met à jour le deck sélectionné
  };

  // Fonction pour ajouter un nouveau deck (livre)
  const handleAddDeck = () => {
    setDecks((prevDecks) => [...prevDecks, prevDecks.length + 1]); // Ajoute un nouveau deck à la liste
  };

  return (
    <div className="navbarre">
      {decks.map((deckNumber, index) => (
        <Buttoncomp
          key={deckNumber}
          title={`Bouton de Livre ${deckNumber}`}
          message={
            // Utiliser index pour accéder au bon titre dans titlebook
            titlebook && titlebook[index] && titlebook[index].title 
              ? `${titlebook[index].title}` 
              : `Livre ${deckNumber}`
          }
          // Si le deck est sélectionné, appliquer une classe spéciale "active"
          className={selectedDeck === deckNumber ? "active" : "inactive"}
          onClick={() => handleDeckClick(deckNumber)} // Met à jour le deck actif
        />
      ))}

      {/* Bouton pour ajouter un nouveau livre */}
      <Buttoncomp
        title={"Ajouter un livre"}
        className={"add-button"} // Ajoutez une classe personnalisée pour le style
        message={"+"}
        onClick={handleAddDeck}
      />
    </div>
  );
};

export default Navbarre;
