import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../helpers/AuthContext";
import Buttoncomp from "../../../UI/Buttoncomp";
import Navbarre from "../../../component/Navbarre";
import Pages from "./PagesCreationBook";

function CreationBook() {
  const [selectedDeck, setSelectedDeck] = useState(1);
  const [statemodif, setStatemodif] = useState(false);
  const [navbarreperso, setNavbarreperso] = useState(true);
  const [baseButtonText, setBaseButtonText] = useState("Horizontale");
  const [titlebook, SetTitrebook] = useState([]);
  const [idactif, setidactif] = useState(false);

  const { authState } = useContext(AuthContext);

  const handleDeckClick = (deckNumber) => {
    setSelectedDeck(deckNumber);
  };

  const toggleStatemodif = () => {
    setStatemodif((prevState) => !prevState);
  };

  const toggleNavbarreperso = () => {
    setNavbarreperso((prevState) => !prevState);
    setSelectedDeck(90);
    setBaseButtonText((prevState) =>
      prevState === "Horizontale" ? "Vertical" : "Horizontale"
    );
  };

  // Affichage dans la console pour déboguer
  console.log(titlebook && titlebook.length > 0 ? titlebook[0].title : "Pas de titre disponible", "titlebook.title");

  return (
    <>
      <div className="creationbook">
        <div>
          <Buttoncomp
            onClick={toggleStatemodif}
            message={"Création"}
            className="buttondeck_css"
          />

          <Buttoncomp
            message={baseButtonText}
            className="buttondeck_css"
            onClick={toggleNavbarreperso}
          />
        </div>
    
        <Navbarre
          setSelectedDeck={setSelectedDeck}
          selectedDeck={selectedDeck}
          titlebook={titlebook}
        />

        <div className="selected-book-title">
          {/* Vérification si titlebook est un tableau et contient des éléments */}
          <h2 className="title_book">
            {titlebook && Array.isArray(titlebook) && titlebook[0] 
              ? titlebook[0].title 
              : "Aucun livre sélectionné"}
          </h2>
        </div>

        <Pages
          deckNumber={selectedDeck}
          setSelectedDeck={setSelectedDeck}
          statemodif={statemodif}
          idactif={idactif}
          setidactif={setidactif}
          titlebook={titlebook}
          SetTitrebook={SetTitrebook}
        />
      </div>
    </>
  );
}

export default CreationBook;
