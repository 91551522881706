import React, { useState, useEffect } from "react";
import TurnLivreOnboarding from "../../../component/TurnLivreOnboarding";
import TurnLivreOnboardingAudio from "../../../component/Audio/TurnLivreOnboardingaudio";

const Onboarding = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageFlipAudio, setCurrentPageFlipAudio] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div className="imageaccueil">
        <TurnLivreOnboarding
          onPageChange={handlePageChange}
          CurrentPageFlipAudio={currentPageFlipAudio}
        />

        <TurnLivreOnboardingAudio
          pageNumber={currentPage}
          setCurrentPage={setCurrentPage}
          setCurrentPageFlipAudio={setCurrentPageFlipAudio}
        />
      </div>
    </>
  );
};

export default Onboarding;
