import Logincomposant from "../../../Users/Login.js";
import Backgroundbase from "../../../logos/world.svg";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../helpers/AuthContext.js";
import Onboarding from "./Onboarding.js";
import backgroundbase from "../../../logos/world.svg";
import background2 from "../../../logos/space.jpg"; // Ajout de l'arrière-plan supplémentaire
import axios from "axios";
import { apiUrl } from "../../../config.js";
function Accueil({id, number }) {
  const { authState, setAuthState } = useContext(AuthContext);
  const [login, setlogin] = useState(false);
  const [enr, setenr] = useState(false);
  const [LoginVisible, setLoginVisible] = useState(true);
  const [urlbackground, setUrlBackground] = useState(); 
  const [selectedOption, setSelectedOption] = useState("option1");

  const [mybook, setmybook] = useState(); 
  const [myid, setmyid] = useState(); 



   
  
  useEffect(() => {

    let myBookData = localStorage.getItem("mybook");
   let  myIdData = localStorage.getItem("myid");

   
    if (myBookData  && myIdData ) {
      setmybook(myBookData);
      setmyid(myIdData);
    } else {
      setmybook(1);
      setmyid(1);
    }
  


}, []);
 
  

 // Charger les options depuis le localStorage au démarrage
 useEffect(() => {
  const listBackground = localStorage.getItem("listbackground");
  const storedUrlsFromLocalStorage = localStorage.getItem("storedUrls");

  if (listBackground) {
    setSelectedOption(listBackground); // Charger l'option sélectionnée
     // Appliquer la bonne URL
  }


}, []);
  // Appel à l'API pour récupérer l'URL du fond "Book" lorsque l'option 2 est sélectionnée
  useEffect(() => {
    if (selectedOption === "option2") {
      axios
        .get(`${apiUrl}/postimages/lirefond/${myid}/${mybook}`, {
          headers: { accessToken: localStorage.getItem("accessToken") },
        })
        .then((response) => {
          if (response.data && response.data[0]) {
            setUrlBackground(response.data[0].lien); 
            console.log(urlbackground, "urlbackgroundurlbackground")// Appliquer directement l'URL récupérée
          }
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération du fond 'Book':", error);
        });
    }
  }, [selectedOption, id, number]); // Déclenche l'appel uniquement si l'option 2 est sélectionnée
  // Mappage des options d'arrière-plan
  const backgroundMap = {
    option1: backgroundbase,
    option2: urlbackground,
    option3: background2, // Ajout de l'option pour l'arrière-plan "Book"
  }; 
  useEffect(() => {
    setAuthState((prevState) => ({
      ...prevState,
      urlcontextbackground: urlbackground,
    }));
  }, [urlbackground, setAuthState]);
  

  useEffect(() => {
    const savedBackgroundUrl = localStorage.getItem("listbackground");

    // Vérifiez si un arrière-plan est déjà défini dans l'état d'authentification
    if (savedBackgroundUrl && !authState.urlcontextbackground) {
      const resolvedBackground = backgroundMap[savedBackgroundUrl] || savedBackgroundUrl;
      setAuthState((prevState) => ({
        ...prevState,
        urlcontextbackground: resolvedBackground,
      }));
    }
  }, [authState.urlcontextbackground, setAuthState]);

  const handleClick = () => {
    setlogin((prevLogin) => !prevLogin);
    setenr(false);
    setLoginVisible(!LoginVisible);
  };

  // Déterminer l'image de fond à utiliser
  const backgroundImage = authState.urlcontextbackground
    ? `url(${authState.urlcontextbackground})`
    : `url(${Backgroundbase})`;

  return (
    <>
      <div
        className="container"
        style={{
          backgroundImage: backgroundImage,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          margin: 0,
          padding: 0,
          transition: "opacity 1.5s ease-in-out", // Transition sur 1.5 secondes
        }}
      >
        {!authState.status && (
          <div onClick={handleClick}>
            {LoginVisible ? (
              <span className="boutonlogin"></span>
            ) : (
              <span className="boutonlogin2"></span>
            )}
          </div>
        )}

        <div className="screen-center"></div>
        <Onboarding />

        {login && (
          <div>
            <Logincomposant />
          </div>
        )}
      </div>
    </>
  );
}

export default Accueil;
