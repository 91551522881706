import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../helpers/AuthContext";
import { apiUrl } from "../config";

function Cartes() {
  const { authState, setAuthState } = useContext(AuthContext);
  const [cartes, setCartes] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      history.push("/login");
    }
  }, [history]);

  useEffect(() => {
    const fetchCartes = async () => {
      try {
        const fetchedCartes = [];
        // Utiliser une boucle pour récupérer chaque livre un par un
        for (let num = 1; num <= 100; num++) {
          const response = await axios.get(`${apiUrl}/postimages/lireimagespresentation/${authState.id}/${num}`, {
            headers: { accessToken: localStorage.getItem("accessToken") },
          });
          // Assurez-vous que les données sont valides avant d'ajouter
          if (response.data && response.data.length > 0) {
            fetchedCartes.push(response.data[0]); // On pousse le premier élément seulement
          }
        }
        setCartes(fetchedCartes);
      } catch (err) {
        console.error("Failed to fetch cartes:", err);
      }
    };

    if (authState.id) {
      fetchCartes();
    }
  }, [authState.id]);

  const handleCardClick = (number) => {
    history.push("/Monlivre", { number });
    setAuthState((prevState) => ({
      ...prevState,
      bibli: true,
      identity: true,
      accueil: true,
      create: true,
    }));
  };

  return (
    <div className="calquesdeschoix">
      {cartes.map((carte, index) => (
        <div key={index} className="carte" onClick={() => handleCardClick(index + 1)}>
          {carte && (
            <>
              <img src={carte.lien} alt={`Le livre ${index + 1}`} />
              <p>{carte.title}</p>
            </>
          )}
        </div>
      ))}
    </div>
  );
}

export default Cartes;
