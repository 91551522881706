import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../../helpers/AuthContext";
import { apiUrl } from "../../../config";
import TurnLivreOnboarding from "../../../component/TurnLivreOnboarding";
import TurnLivreOnboardingAudio from "../../../component/Audio/TurnLivreOnboardingaudio";
const MonLivre = (props) => {
  const { authState } = useContext(AuthContext);
  const { number } = props.location.state;
  const [Deckstatefond, SetDeckstatefond] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageFlipAudio, setCurrentPageFlipAudio] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    axios
      .get(
        `${apiUrl}/postimages/lirefond/${authState.id}/${number}`,

        {
          headers: { accessToken: localStorage.getItem("accessToken") },
        }
      )
      .then((response) => {
        SetDeckstatefond(response.data[0]);
      });
  }, [authState, number]);

  useEffect(() => {
    if (Deckstatefond) {
      document.documentElement.style.setProperty(
        "--livre-background",
        `url(${Deckstatefond.lien})`
      );
    }
  }, [Deckstatefond]);
  return (
    <>
      {Deckstatefond && (
        <div
          style={{
            background: "var(--livre-background)",
            backgroundSize: "cover",
            backgroundPosition: "top",
            backgroundRepeat: "repeat",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <div>
            <TurnLivreOnboarding
              number={number}
              onPageChange={handlePageChange}
              CurrentPageFlipAudio={currentPageFlipAudio}
            />
            <TurnLivreOnboardingAudio
              pageNumber={currentPage}
              setCurrentPage={setCurrentPage}
              setCurrentPageFlipAudio={setCurrentPageFlipAudio}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default MonLivre;
